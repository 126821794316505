<template>
  <div class="mt-4">
    <div class="" v-if="detailObj !== null">
      <div class="card top_primary_card bg-primary font-semibold text-white p-2 heading-4 rounded-xl w-full">
      <div class="flex justify-between items-center">
        <h1 class="text-center pl-1"><span class="pr-2 cursor-pointer" @click="$router.push({ name: 'Plans'})"><i class="fa-solid fa-arrow-left"></i></span>{{detailObj.planName}}</h1>
        <div class="flex items-center gap-3">
          <div class="">
            <span @click="deactivatePlan(false)" v-if="detailObj.deactivatedOn === ''" class="bg-error text-white heading-6 cursor-pointer  px-2.5 py-2 rounded-xl font-medium">Deactive Plan</span>
            <span @click="deactivatePlan(true)" v-if="detailObj.deactivatedOn !== ''" class="bg-white text-primary heading-6 cursor-pointer  px-2.5 py-2 rounded-xl font-medium">Active Plan</span>
          </div>
          <div class=" text-error heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="delteAddressConfirm = true">
            <i class="fa-solid fa-trash-alt"></i>
          </div>
          <div class=" text-primary heading-4 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="editPlan()">
            <i class="fa-solid fa-pen-to-square"></i>
          </div>
        </div>
      </div>
    </div>
      <!-- :style="`height:` + cardHeight + `px;max-height:` + cardHeight + `px;overflow: auto; !important`" -->
        <div class="lg:grid grid-cols-5 gap-4" >
          <div class="col-span-2">
            <div class="card rounded-xl bg-white p-2 my-2">
              <div class="heading-3 text-text2">● Detail:</div>
              <div>
                <div class="p-1">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Short Title</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5">{{detailObj.shortTitle !== '' ? detailObj.shortTitle : '-'}}</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Plan Button Text</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5">{{detailObj.planButtonTitle !== '' ? detailObj.planButtonTitle : '-'}}</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Max User Title</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5">{{detailObj.planMaxUserTitle !== '' ? detailObj.planMaxUserTitle : '-'}}</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Created On</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5">{{detailObj.createdOn | dateTimeWithName}}</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Updated On</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5">{{detailObj.updatedOn | dateTimeWithName}}</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Plan Active</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class=" font-semibold heading-5" :class="detailObj.isActive ? 'text-success' : 'text-error'">{{detailObj.isActive ? 'Active' : 'Inactive'}}</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">Plan Subscription</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class=" font-semibold heading-5" :class="detailObj.isFree ? 'text-text1' : 'text-warning'">{{detailObj.isFree ? 'Free' : 'Paid'}}</span>
                  </p>
                </div>
                <div class="p-1" v-if="detailObj.isFree">
                  <p class="mb-0 items-start flex">
                    <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:180px">No of Days</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 font-semibold heading-5">{{detailObj.noOfDays}} Day</span>
                  </p>
                </div>
                <div class="p-1" v-if="detailObj.deactivatedOn !== ''">
                  <p class="mb-0 items-start flex">
                    <span class="text-error heading-5 font-semibold"><p class="mb-0" style="width:180px">Deactivated On</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-error font-semibold heading-5">{{detailObj.deactivatedOn | dateTimeWithName}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card rounded-xl bg-white p-2 my-2" v-if="detailObj.shortInfo !== ''">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0">Short Feature List:</p></span>
              <div class="text-text1 font-semibold heading-5 ml-2 ql-editor ql-editor-overflow-inherit" v-html="detailObj.shortInfo"></div>
            </div>
            <div class="card rounded-xl bg-white p-2 my-2" v-if="detailObj.planSubscriptions !== null">
              <div class="heading-3 text-text2">● Plan Subscription:</div>
              <div class="mt-2">
                <div class="p-1 border border-gray-300 rounded-xl mb-2" v-for="(data, index) in detailObj.planSubscriptions" :key="index">
                  <!-- <div class="text-gray4">{{ index + 1 }})</div> -->
                  <div class="p-2 ">
                    <div >
                      <p class="mb-0 items-start flex">
                        <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Currency Title</p></span>
                        <span><p class="mb-0 dotwidth">:</p></span>
                        <span class="text-text1 font-semibold heading-5">{{data.countryTitle}}</span>
                      </p>
                    </div>
                    <div >
                      <p class="mb-0 items-start flex">
                        <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Country Code</p></span>
                        <span><p class="mb-0 dotwidth">:</p></span>
                        <span class="text-text1 font-semibold heading-5">{{data.countryCode}}</span>
                      </p>
                    </div>
                    <div >
                      <p class="mb-0 items-start flex">
                        <span class="text-text1 heading-5"><p class="mb-0" style="width:170px">Currency Symbol</p></span>
                        <span><p class="mb-0 dotwidth">:</p></span>
                        <span class="text-text1 font-semibold heading-5">{{data.currencySymbol}}</span>
                      </p>
                    </div>
                    <div class="p-1" v-for="(dataA, indexA) in data.paymentOption.paymentOption" :key="indexA">
                      <div class="pl-2 card rounded-xl bg-white p-2 my-1 border ">
                        <div>
                          <p class="mb-0 items-start flex">
                            <span class="text-text1 heading-5"><p class="mb-0" style="width:170px">Payment Type</p></span>
                            <span><p class="mb-0 dotwidth">:</p></span>
                            <span class="text-text1 font-semibold heading-5">{{dataA.paymentType === 'm' ? 'Monthly' : 'Yearly'}}</span>
                          </p>
                        </div>
                        <div>
                          <p class="mb-0 items-start flex">
                            <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Payment Title</p></span>
                            <span><p class="mb-0 dotwidth">:</p></span>
                            <span class="text-text1 font-semibold heading-5">{{dataA.paymentTitle}}</span>
                          </p>
                        </div>
                        <div>
                          <p class="mb-0 items-start flex">
                            <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Save Amount Title</p></span>
                            <span><p class="mb-0 dotwidth">:</p></span>
                            <span class="text-text1 font-semibold heading-5">{{dataA.saveAmountText}}</span>
                          </p>
                        </div>
                        <div>
                          <p class="mb-0 items-start flex">
                            <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Payment Id</p></span>
                            <span><p class="mb-0 dotwidth">:</p></span>
                            <span class="text-text1 font-semibold heading-5 overFlowParaA">{{dataA.paymentId}}</span>
                          </p>
                        </div>
                        <div>
                          <p class="mb-0 items-start flex">
                            <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Payment Link</p></span>
                            <span><p class="mb-0 dotwidth">:</p></span>
                            <span class="text-blue-400 font-semibold heading-5 cursor-pointer overFlowParaA" @click="redirectToLink(dataA.paymentLink)">{{dataA.paymentLink}}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-3" v-show="detailObj.planFeatures !== null">
            <div class="card rounded-xl bg-white p-4 my-2">
              <div class="heading-3 text-text2 pb-2">● Plan Features:</div>
              <!-- <div :style="`height:` + secondHeight + `px;max-height:` + secondHeight + `px;overflow: auto; !important`"> -->
                <div style="overflow-y: auto; white-space: nowrap;" class="table_containder">
                  <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 p-1 items-center">
                    <div class="name_col flex items-center">
                      Title
                    </div>
                    <div class="name_col2 flex items-center">
                      Description
                    </div>
                    <div class="name_col3 flex items-center">
                      Number
                    </div>
                  </div>
                  <div class="p-2 min-w-min group flex border-r border-l border-b border-gray1  bg-white  text-sm items-center  hover:bg-gray-50 cursor-pointer" v-for="(data, index) in detailObj.planFeatures" :key="index">
                    <div class="name_col" >
                      <span class="heading-5 text-text1" v-if="data.displayText !== ''">{{data.displayText}}</span>
                      <span class="" v-else>--</span>
                    </div>
                    <div class="name_col2" >
                      <span class="heading-5 text-text1" v-if="data.description !== ''">{{data.description}}</span>
                      <span class="" v-else>--</span>
                    </div>
                    <div class="name_col3" >
                      <span class="heading-5 text-text1" v-if="data.numberVal !== ''">{{data.numberVal}}</span>
                      <span class="" v-else>--</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="p-2 border border-gray-300 rounded-xl m-2" v-for="(data, index) in detailObj.planFeatures" :key="index">
                  <div>
                    <p class="mb-0 items-start flex">
                      <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Title</p></span>
                      <span><p class="mb-0 dotwidth">:</p></span>
                      <span class="text-text1 font-semibold heading-5">{{data.displayText !== '' ? data.displayText : '-'}}</span>
                    </p>
                  </div>
                  <div>
                    <p class="mb-0 items-start flex">
                      <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Number</p></span>
                      <span><p class="mb-0 dotwidth">:</p></span>
                      <span class="text-text1 font-semibold heading-5">{{data.numberVal}}</span>
                    </p>
                  </div>
                  <div>
                    <p class="mb-0 items-start flex">
                      <span class="text-text1 heading-5 "><p class="mb-0" style="width:170px">Description</p></span>
                      <span><p class="mb-0 dotwidth">:</p></span>
                      <span class="text-text1 font-semibold heading-5">{{data.description !== '' ? data.description : '-'}}</span>
                    </p>
                  </div>
                </div> -->
            </div>
          </div>
        </div>
    </div>
    <div v-if="delteAddressConfirm" class="popup_overlay relative px-4">
       <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
          <div  class="mb-5 font-semibold">
            <p class="heading-3 text-text1">Are you sure to delete this Plan?</p>
          </div>
          <div class="flex gap-2 justify-end">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="delteAddressConfirm = false"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deletePlan"/>
          </div>
      </div>
    </div>
   </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  name: "customer",
  components: {
    Button
  },
  mixins: [deboucneMixin],
  data() {
    return {
      delteAddressConfirm: false,
      cardHeight: 0,
      secondHeight: 0,
      detailObj: null,
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.getPlanDetail()
  },
  beforeDestroy() {
  },
  methods: {
    getPlanDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetPlanDetail(
        parseInt(this.$route.params.planId),
        response => {
          this.detailObj = response.Data === null ? [] : response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    deactivatePlan (data) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.ActiveDeactivePlan(
        parseInt(this.$route.params.planId),
        data ? 'active' : 'deactive',
        response => {
          this.getPlanDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    deletePlan () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.DeletePlanApi(
        parseInt(this.$route.params.planId),
        response => {
          this.$router.push({ name: 'Plans'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    editPlan () {
      this.$router.push({name: 'EditNewPlan', params: {planId: parseInt(this.$route.params.planId)}})
    },
    redirectToLink (data) {
      window.open(data, "_blank");
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 178
      this.secondHeight = window.innerHeight - 238
    },
  },
};
</script>
<style scoped>
.dotwidth {
    width: 15px;
  }
  .name_col {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
  .name_col2 {
  min-width: 480px !important;
  width: 480px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
  .name_col3 {
  min-width: 190px !important;
  width: 190px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>